import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Breadcrumb from '../../components/breadcrumb'
import SectorPage from '../../components/sectorPage'

const SupermarketPage = ({location}) => {
  return (
    <Layout year="2021" simpleHeader={true} url="/2021/grande-distribution" type="sector">
      <SEO 
        title="Classement des meilleurs sites de la grande distribution - 2021"
        description="Quels sont les meilleurs sites de la grande distribution de l'Observatoire de l'impact positif ? Points forts et les points faibles des sites de drive français."
        path="/2021/grande-distribution"
        year="2021" />
      
      <Breadcrumb url="/2021/grande-distribution" label="Grande distribution" year="2021" /> 

      <SectorPage slug="supermarket" type="sector" year="2021" />
    </Layout>
  )
  }

export default SupermarketPage

